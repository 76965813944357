import { Injectable } from '@angular/core';
import { AscendAddendumEnums } from '../classes/ascendAddendumEnums';
import { CardiacAddendum2022Enums } from '../classes/cardiacAddendum2022Enums';
import { CardiacOldAddendumEnums } from '../classes/cardiacOldAddendumEnums';
import { CovidAddendumEnums } from '../classes/covidAddendumEnums';
import { DeviceEnums } from '../classes/deviceEnums';
import { ECPRAddendum2020Enums } from '../classes/ecprAddendum2020Enums';
import { ECPROldAddendumEnums } from '../classes/ecprOldAddendumEnums';
import { InfectionEnums } from '../classes/infectionEnums';
import { SeverityScoresEnums } from '../classes/severityScoresEnums';
import { TraumaAddendumEnums } from '../classes/traumaAddendumEnums';

Injectable()
export class EnumService {

  constructor() { }

  ECPRAddendum2020Enums: ECPRAddendum2020Enums;
  CardiacAddendumEnums: CardiacAddendum2022Enums;
  CardiacOldAddendumEnums: CardiacOldAddendumEnums;
  CovidAddendumEnums: CovidAddendumEnums;
  SeverityScoresEnums: SeverityScoresEnums;
  TraumaAddendumEnums: TraumaAddendumEnums;
  ECPROldAddendumEnums: ECPROldAddendumEnums;
  AscendAddendumEnums: AscendAddendumEnums;

  InfectionEnums: InfectionEnums;

  DeviceEnums: DeviceEnums;

  enumSystemOnlineOffline = [
    { name: 'Online', value: false },
    { name: 'Offline', value: true }
  ];

  enumExtubated = [
    { name: 'Orotracheally extubated', value: 0 },
    { name: 'N/A tracheostomy', value: 1 },
    { name: 'N/A transferred intubated', value: 2 },
    { name: 'N/A still intubated at time of death', value: 3 },
    { name: 'N/A other', value: 4 },
    { name: 'Endotracheally extubated >= 48 hours', value: 5 }
  ];
  enumCannulaRepair = [
    { name: 'None', value: 0 },
    { name: 'Common Carotid Artery', value: 1 },
    { name: 'Internal Jugular Vein', value: 2 },
    { name: 'Carotid and Jugular', value: 3 },
    { name: 'Other', value: 4 }
  ];
  enumDiscontinuation = [
    { name: 'Unknown', value: 0 },
    { name: 'Expected Recovery', value: 1 },
    { name: 'Poor Prognosis Followed by Death', value: 2 },
    { name: 'Resource Limitation', value: 6 },
    { name: 'VAD', value: 10 },
    { name: 'Pumpless Lung Assist (PA to LA)', value: 11 },
    { name: 'Heart Tx', value: 12 },
    { name: 'Lung Tx', value: 13 },
    { name: 'Heart/Lung Tx', value: 14 },
    { name: 'Complication', value: 15 },
    { name: 'Poor Prognosis Followed by Unexpected Survival', value: 16 }
  ];
  enumDischargedAlive = [
    { name: 'No', value: 0 },
    { name: 'Yes', value: 1 },
    { name: 'On ECMO', value: 2 }
  ];
  enumDischargeLocation = [
    { name: 'Home', value: 1, disabled: false },
    { name: 'Other, Unknown', value: 2, disabled: false },
    { name: 'Transferred to another hospital', value: 3, disabled: false },
    { name: 'Transfer to LTAC or Rehab', value: 4, disabled: true },
    { name: 'Transfer to hospice', value: 5, disabled: false },
    { name: 'Transfer to LTAC', value: 6, disabled: false },
    { name: 'Transfer to Rehab', value: 7, disabled: false }
  ];
  //ECPR 2020
  //enumECPRAntecedentEventCodes: any;
  //enumCMConditionCodes: any;
  //enumECPROOHCACodes: any;
  //enumECPR2020CompressionMethodsUsed: any;
  //enumECPR2020MedicationCodes: any;
  //enumECPR2020OiDuringCPAcodes: any;
  
}
