import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { AppService } from '../services/app.service';
import { DateTimeFormatService } from '../services/datetime-format.service';

@Pipe({
  name: 'dateFormat',
  pure: false
})
export class DateFormatPipe implements PipeTransform {

  constructor(public dateTimeService: DateTimeFormatService, public appService: AppService) {

  }
  transform(date: any): any {
    if (date != null) {
      const locale = this.dateTimeService.locale;
      const format = this.dateTimeService.format;

      //const result = moment(new Date(date)).utc(true).locale(locale).format(format);//commented on 04/25/2024
      const result = this.appService.toMomentDate(date).locale(locale).format(format);//added on 04/25/2024

      //date = date.replace('Z', '');
      //const result = moment(new Date(date)).utc(true).locale(locale).format(format);

      return result;
    } else {
      return 'Not Entered';
    }
  }
}
