<div [hidden]="appService.isLoading | async" style="height: 1000px; overflow-y: scroll; ">
  <br /><br /> <br />
  <div style="border: 5px solid #3F51B5; width: fit-content; border-radius: 15px; padding: 3%; margin: auto;">
    <img style="width: 200px;" class="" src="../assets/images//elso-logo-big.png">
    <br /><br /><br />
    Loging out...
  </div>

  <mat-spinner style="position: absolute; top: 0; z-index: 5000; top: 40%; left: 48.2%;"></mat-spinner>
</div>


