import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';


Injectable()
export class PaginatorService {

  constructor() {
  }

  public lowValue: number = 0;
  public highValue: number = 5;

  public getPaginatorData(event: PageEvent): PageEvent {

    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }
}
