<div [hidden]="appService.isLoading | async">
  <form [formGroup]="form">

    <fieldset *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only') || appService.UserProfile.UserName == 'barbaror@med.umich.edu' || appService.UserProfile.UserName == 'kellimcd@med.umich.edu'">
      <legend>ASCEND data export</legend>
      <button class="form-execute-buttons" (click)="createAscendDataDump()" type="button" color="primary" mat-raised-button>Export ASCEND data</button>
    </fieldset>
    <br />
    <fieldset>
      <legend>
        Data export
      </legend>
      <div class="control-item" *ngIf="!showCentersList">
        <mat-label>Center: {{appService.UserProfile.CenterName}}</mat-label>
      </div>
      <div class="control-item" *ngIf="showCentersList">
        <label for="Center">Center:</label>
        <br />
        <mat-form-field class="select-form-field">
          <mat-select hideSingleSelectionIndicator id="Center" name="Center" placeholder="Please select" formControlName="CenterId">
            <mat-option>Please select</mat-option>
            <mat-option *ngFor="let center of centers" [value]="center.CenterId">
              {{center.CenterNo}} - {{center.Name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="control-item">
        <label>Start time:</label>
        <br />
        <elso-datetime-picker (customOnChange)="validateStartTimeVsEndTime()" [model]="form.get('StartTime')" id="StartTime"></elso-datetime-picker>
      </div>
      <div class="control-item">
        <label>End time:</label>
        <br />
        <elso-datetime-picker (customOnChange)="validateStartTimeVsEndTime()" [model]="form.get('EndTime')" id="EndTime"></elso-datetime-picker>
      </div>
      <div class="hard-error" *ngIf="form.controls['StartTime'].hasError('startTimevsEndTime')">Start time must be before end time!</div>
    </fieldset>
    <button [routerLink]="['/home']" type="button" class="form-execute-buttons" color="primary" mat-raised-button>Back</button>
    <button class="form-execute-buttons" type="button" color="primary" (click)="save()" mat-raised-button [disabled]="form.invalid">Generate data dump</button>
  </form>
</div>
