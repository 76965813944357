import { Component } from '@angular/core';


@Component({
  selector: 'for-tooltip',
  template: `
    <span style="color: cyan">Tooltip from Componentt</span>
  `,
})
export class ForTooltipComponent {


}
