import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateTimeFormatService } from '../../services/datetime-format.service';
import { Moment } from 'moment';
//import * as moment from 'moment';
import { DateValidationService } from '../../services/date-validation.service';
import { AppService } from '../../services/app.service';
import { Observable } from 'rxjs';
import { DataService } from '../../services/data.service';
import { OwlDateTimeComponent } from '@danielmoncada/angular-datetime-picker';

@Component({
  selector: 'elso-datetime-picker',
  templateUrl: './elso-datetimepicker.component.html',
  styleUrls: ['./elso-datetimepicker.component.css']
})

export class ELSODateTimePickerComponent {
  @Input() public model: FormControl;
  @Input() public id: string;
  @Input() public sectionName: string;
  @Input() public pickerType: string;
  @Input() public disablePickerReset: string;
  @Input() public otherDateComparsion: string;
  @Input() public dateBehind: FormControl;
  @Input() public dateFront: FormControl;
  @Input() public specialValidation: string;
 // @Input() public customOnChange: any;
  @Output() customOnChange: EventEmitter<any> = new EventEmitter();
  //@Input() onSuggestTest: (arg: FormControl, argg: FormControl) => void;
  isFired: boolean;

  /*OLD REGISTRY DATE PICKER*/
  showDatePicker = true;
  showTimePicker = true;

  showMonthPicker = true;
  showMonthLabel = true;
  showMonthList = false;
  showMonthInput = false;
  monthModel = null;

  showDayPicker = true;
  showDayLabel = true;
  showDayList = false;
  showDayInput = false;
  dayModel = null;

  showYearPicker = true;
  showYearLabel = true;
  showYearList = false;
  showYearInput = false;
  yearModel = null;

  showHourPicker = true;
  showHourLabel = true;
  showHourList = false;
  showHourInput = false;
  hourModel = null;

  showMinutePicker = true;
  showMinuteLabel = true;
  showMinuteList = false;
  showMinuteInput = false;
  minuteModel = null;

  showAmPmPicker = false;
  showAmPmLabel = true;
  showAmPmList = false;
  showAmPmInput = false;
  ampmModel = null;

  ampms = [
    { name: 'AM/PM', id: '', behindFilter: 'z' },
    { name: 'AM', id: 0, behindFilter: 'am0' },
    { name: 'PM', id: 1, behindFilter: 'pm1' }
  ];

  months = [
    { name: 'Month', id: '', behindFilter: 'z' },
    { name: 'Jan', id: 0, behindFilter: 'Jan01' },
    { name: 'Feb', id: 1, behindFilter: 'Feb02' },
    { name: 'Mar', id: 2, behindFilter: 'Mar03' },
    { name: 'Apr', id: 3, behindFilter: 'Apr04' },
    { name: 'May', id: 4, behindFilter: 'May05' },
    { name: 'Jun', id: 5, behindFilter: 'Jun06' },
    { name: 'Jul', id: 6, behindFilter: 'Jul07' },
    { name: 'Aug', id: 7, behindFilter: 'Aug08' },
    { name: 'Sep', id: 8, behindFilter: 'Sep09' },
    { name: 'Oct', id: 9, behindFilter: 'Oct10' },
    { name: 'Nov', id: 10, behindFilter: 'Nov11' },
    { name: 'Dec', id: 11, behindFilter: 'Dec12' }
  ];

  days = [
    { name: 'Day', id: '', behindFilter: 'z' },
    { name: '01', id: 1, behindFilter: '1' },
    { name: '02', id: 2, behindFilter: '2' },
    { name: '03', id: 3, behindFilter: '3' },
    { name: '04', id: 4, behindFilter: '4' },
    { name: '05', id: 5, behindFilter: '5' },
    { name: '06', id: 6, behindFilter: '6' },
    { name: '07', id: 7, behindFilter: '7' },
    { name: '08', id: 8, behindFilter: '8' },
    { name: '09', id: 9, behindFilter: '9' },
    { name: '10', id: 10, behindFilter: '10' },
    { name: '11', id: 11, behindFilter: '11' },
    { name: '12', id: 12, behindFilter: '12' },
    { name: '13', id: 13, behindFilter: '13' },
    { name: '14', id: 14, behindFilter: '14' },
    { name: '15', id: 15, behindFilter: '15' },
    { name: '16', id: 16, behindFilter: '16' },
    { name: '17', id: 17, behindFilter: '17' },
    { name: '18', id: 18, behindFilter: '18' },
    { name: '19', id: 19, behindFilter: '19' },
    { name: '20', id: 20, behindFilter: '20' },
    { name: '21', id: 21, behindFilter: '21' },
    { name: '22', id: 22, behindFilter: '22' },
    { name: '23', id: 23, behindFilter: '23' },
    { name: '24', id: 24, behindFilter: '24' },
    { name: '25', id: 25, behindFilter: '25' },
    { name: '26', id: 26, behindFilter: '26' },
    { name: '27', id: 27, behindFilter: '27' },
    { name: '28', id: 28, behindFilter: '28' },
    { name: '29', id: 29, behindFilter: '29' },
    { name: '30', id: 30, behindFilter: '30' },
    { name: '31', id: 31, behindFilter: '31' }
  ];

  years: any = [
    { name: 'Year', id: 0, behindFilter: 'z' }
  ];

  hours: any = [
    { name: 'Hour', id: '', behindFilter: 'z' },
    { name: '00', id: 0, behindFilter: '0' },
    { name: '01', id: 1, behindFilter: '1' },
    { name: '02', id: 2, behindFilter: '2' },
    { name: '03', id: 3, behindFilter: '3' },
    { name: '04', id: 4, behindFilter: '4' },
    { name: '05', id: 5, behindFilter: '5' },
    { name: '06', id: 6, behindFilter: '6' },
    { name: '07', id: 7, behindFilter: '7' },
    { name: '08', id: 8, behindFilter: '8' },
    { name: '09', id: 9, behindFilter: '9' },
    { name: '10', id: 10, behindFilter: '10' },
    { name: '11', id: 11, behindFilter: '11' },
    { name: '12', id: 12, behindFilter: '12' },
    { name: '13', id: 13, behindFilter: '13' },
    { name: '14', id: 14, behindFilter: '14' },
    { name: '15', id: 15, behindFilter: '15' },
    { name: '16', id: 16, behindFilter: '16' },
    { name: '17', id: 17, behindFilter: '17' },
    { name: '18', id: 18, behindFilter: '18' },
    { name: '19', id: 19, behindFilter: '19' },
    { name: '20', id: 20, behindFilter: '20' },
    { name: '21', id: 21, behindFilter: '21' },
    { name: '22', id: 22, behindFilter: '22' },
    { name: '23', id: 23, behindFilter: '23' }
  ];

  minutes = [
    { name: 'Minute', id: '', behindFilter: 'z' },
    { name: '00', id: 0, behindFilter: '0' },
    { name: '01', id: 1, behindFilter: '1' },
    { name: '02', id: 2, behindFilter: '2' },
    { name: '03', id: 3, behindFilter: '3' },
    { name: '04', id: 4, behindFilter: '4' },
    { name: '05', id: 5, behindFilter: '5' },
    { name: '06', id: 6, behindFilter: '6' },
    { name: '07', id: 7, behindFilter: '7' },
    { name: '08', id: 8, behindFilter: '8' },
    { name: '09', id: 9, behindFilter: '9' },
    { name: '10', id: 10, behindFilter: '10' },
    { name: '11', id: 11, behindFilter: '11' },
    { name: '12', id: 12, behindFilter: '12' },
    { name: '13', id: 13, behindFilter: '13' },
    { name: '14', id: 14, behindFilter: '14' },
    { name: '15', id: 15, behindFilter: '15' },
    { name: '16', id: 16, behindFilter: '16' },
    { name: '17', id: 17, behindFilter: '17' },
    { name: '18', id: 18, behindFilter: '18' },
    { name: '19', id: 19, behindFilter: '19' },
    { name: '20', id: 20, behindFilter: '20' },
    { name: '21', id: 21, behindFilter: '21' },
    { name: '22', id: 22, behindFilter: '22' },
    { name: '23', id: 23, behindFilter: '23' },
    { name: '24', id: 24, behindFilter: '24' },
    { name: '25', id: 25, behindFilter: '25' },
    { name: '26', id: 26, behindFilter: '26' },
    { name: '27', id: 27, behindFilter: '27' },
    { name: '28', id: 28, behindFilter: '28' },
    { name: '29', id: 29, behindFilter: '29' },
    { name: '30', id: 30, behindFilter: '30' },
    { name: '31', id: 31, behindFilter: '31' },
    { name: '32', id: 32, behindFilter: '32' },
    { name: '33', id: 33, behindFilter: '33' },
    { name: '34', id: 34, behindFilter: '34' },
    { name: '35', id: 35, behindFilter: '35' },
    { name: '36', id: 36, behindFilter: '36' },
    { name: '37', id: 37, behindFilter: '37' },
    { name: '38', id: 38, behindFilter: '38' },
    { name: '39', id: 39, behindFilter: '39' },
    { name: '40', id: 40, behindFilter: '40' },
    { name: '41', id: 41, behindFilter: '41' },
    { name: '42', id: 42, behindFilter: '42' },
    { name: '43', id: 43, behindFilter: '43' },
    { name: '44', id: 44, behindFilter: '44' },
    { name: '45', id: 45, behindFilter: '45' },
    { name: '46', id: 46, behindFilter: '46' },
    { name: '47', id: 47, behindFilter: '47' },
    { name: '48', id: 48, behindFilter: '48' },
    { name: '49', id: 49, behindFilter: '49' },
    { name: '50', id: 50, behindFilter: '50' },
    { name: '51', id: 51, behindFilter: '51' },
    { name: '52', id: 52, behindFilter: '52' },
    { name: '53', id: 53, behindFilter: '53' },
    { name: '54', id: 54, behindFilter: '54' },
    { name: '55', id: 55, behindFilter: '55' },
    { name: '56', id: 56, behindFilter: '56' },
    { name: '57', id: 57, behindFilter: '57' },
    { name: '58', id: 58, behindFilter: '58' },
    { name: '59', id: 59, behindFilter: '59' }
  ];

  filteredMonths = this.months;
  filteredDays = this.days;
  filteredYears = this.years;
  filteredHours = this.hours;
  filteredMinutes = this.minutes;
  filteredAmPms = this.ampms;

  selectedMonth: any = {
    name: 'Month', id: '', behindFilter: 'z'
  };
  selectedDay: any = {
    name: 'Day', id: '', behindFilter: 'z'
  };
  selectedYear: any = {
    name: 'Year', id: '', behindFilter: 'z'
  };
  selectedHour: any = {
    name: 'Hour', id: '', behindFilter: 'z'
  };
  selectedMinute: any = {
    name: 'Minute', id: '', behindFilter: 'z'
  };
  selectedAmPm: any = {
    name: 'AM/PM', id: '', behindFilter: 'z'
  };

  onPickerClick(pickerPart) {

    switch (pickerPart) {
      case 'month':
        this.monthModel = '';
        this.showMonthList = true;
        this.showMonthInput = true;
        this.showMonthLabel = false;
        setTimeout(function () {
          document.getElementById('month').focus();
        }, 0);
        break;
      case 'day':
        this.dayModel = '';
        this.showDayList = true;
        this.showDayInput = true;
        this.showDayLabel = false;
        setTimeout(function () {
          document.getElementById('day').focus();
        }, 0);
        break;
      case 'year':
        this.yearModel = '';
        this.showYearList = true;
        this.showYearInput = true;
        this.showYearLabel = false;
        setTimeout(function () {
          document.getElementById('year').focus();
        }, 0);
        break
      case 'hour':
        this.hourModel = '';
        this.showHourList = true;
        this.showHourInput = true;
        this.showHourLabel = false;
        setTimeout(function () {
          document.getElementById('hour').focus();
        }, 0);
        break;
      case 'minute':
        this.minuteModel = '';
        this.showMinuteList = true;
        this.showMinuteInput = true;
        this.showMinuteLabel = false;
        setTimeout(function () {
          document.getElementById('minute').focus();
        }, 0);
        break;
      case 'ampm':
        this.ampmModel = '';
        this.showAmPmList = true;
        this.showAmPmInput = true;
        this.showAmPmLabel = false;
        setTimeout(function () {
          document.getElementById('ampm').focus();
        }, 0);
        break;
    }
  }
  onValueClick(pickerType, selectedObj) {

    switch (pickerType) {
      case 'month':
        this.monthModel = selectedObj.name;
        this.selectedMonth = selectedObj;
        break;
      case 'day':
        this.dayModel = selectedObj.name;
        this.selectedDay = selectedObj;
        break;
      case 'year':
        this.yearModel = selectedObj.name;
        this.selectedYear = selectedObj;
        break;
      case 'hour':
        this.hourModel = selectedObj.name;
        this.selectedHour = selectedObj;
        break;
      case 'minute':
        this.minuteModel = selectedObj.name;
        this.selectedMinute = selectedObj;
        break;
      case 'ampm':
        this.ampmModel = selectedObj.name;
        this.selectedAmPm = selectedObj;
        break;
    }
  }
  closeInput(pickerPart, closeOption) {
    switch (pickerPart) {
      case 'month':
        this.showMonthList = false;
        this.showMonthInput = false;
        this.showMonthLabel = true;
        if (closeOption == 'tab') {
          if (this.filteredMonths.length > 0) {
            console.log(this.monthModel);
            if (this.selectedMonth.id == '' || this.monthModel != '') {//prevent value reset if nothing is filtered
              this.monthModel = this.filteredMonths[0].name;
              this.selectedMonth = this.filteredMonths[0];
            }
          }
          this.onPickerClick('day');
        }
        this.filteredMonths = this.months;
        break;
      case 'day':
        this.showDayList = false;
        this.showDayInput = false;
        this.showDayLabel = true;
        if (closeOption == 'tab') {
          if (this.filteredDays.length > 0) {
            if (this.selectedDay.id == '' || this.dayModel != '') {//prevent value reset if nothing is filtered
              this.dayModel = this.filteredDays[0].name;
              this.selectedDay = this.filteredDays[0];
            }
          }
          this.onPickerClick('year');
        }
        this.filteredDays = this.days;
        break;
      case 'year':
        this.showYearList = false;
        this.showYearInput = false;
        this.showYearLabel = true;
        if (closeOption == 'tab') {
          if (this.filteredYears.length > 0) {
            if (this.selectedYear.id == '' || this.yearModel != '') {//prevent value reset if nothing is filtered
              this.yearModel = this.filteredYears[0].name;
              this.selectedYear = this.filteredYears[0];
            }
          }

          if (this.showTimePicker == true) {
            this.onPickerClick('hour');
          }
        }
        this.filteredYears = this.years;
        break;
      case 'hour':
        this.showHourList = false;
        this.showHourInput = false;
        this.showHourLabel = true;
        if (closeOption == 'tab') {
          if (this.filteredHours.length > 0) {
            if (this.selectedHour.id == '' || this.hourModel != '') {//prevent value reset if nothing is filtered
              this.hourModel = this.filteredHours[0].name;
              this.selectedHour = this.filteredHours[0];
            }
          }

          this.onPickerClick('minute');
        }
        this.filteredHours = this.hours;
        break;
      case 'minute':
        this.showMinuteList = false;
        this.showMinuteInput = false;
        this.showMinuteLabel = true;
        if (closeOption == 'tab') {
          if (this.filteredMinutes.length > 0) {
            if (this.selectedMinute.id == '' || this.minuteModel != '') {//prevent value reset if nothing is filtered
              this.minuteModel = this.filteredMinutes[0].name;
              this.selectedMinute = this.filteredMinutes[0];
            }
          }

          if (this.showAmPmPicker == true) {
            this.onPickerClick('ampm');
          }
        }
        this.filteredMinutes = this.minutes;
        break;
      case 'ampm':
        this.showAmPmList = false;
        this.showAmPmInput = false;
        this.showAmPmLabel = true;
        if (closeOption == 'tab') {
          if (this.filteredAmPms.length > 0) {
            if (this.selectedAmPm.id == '' || this.ampmModel != '') {//prevent value reset if nothin is filtered
              this.ampmModel = this.filteredAmPms[0].name;
              this.selectedAmPm = this.filteredAmPms[0];
            }
          }

        }
        this.filteredAmPms = this.ampms;
        break;
    }
    var date = this.appService.toMomentCurrentDate();
    var setModel = false;
    var datePartValid = false;
    var timePartValid = false;

    if (this.showDatePicker == true) {

      if (this.selectedMonth.id !== '' && this.selectedDay.id !== '' && this.selectedYear.id !== '') {
        date.set('month', this.selectedMonth.id);
        date.set('date', this.selectedDay.id);
        date.set('year', this.selectedYear.id);

        datePartValid = true;
      }
    } else {
      datePartValid = true;
    }

    if (this.showTimePicker == true) {
      if (this.selectedHour.id !== '' && this.selectedMinute.id !== '') {
        date.set('hour', this.selectedHour.id);
        date.set('minute', this.selectedMinute.id);
        if (this.showAmPmPicker == true) {
          if (this.selectedAmPm.id !== '') {
            if (this.selectedAmPm.name == 'AM') {
              var hourToSet = this.selectedHour.id == 12 ? 0 : this.selectedHour.id;
              console.log(hourToSet);
            }

            if (this.selectedAmPm.name == 'PM') {
              var hourToSet = this.selectedHour.id < 12 ? this.selectedHour.id + 12 : 12;
            }
            date.set('hour', hourToSet);

            timePartValid = true;
          } else {
            timePartValid = false;
          }
        } else {
          timePartValid = true;
        }
      }
    } else {
      timePartValid = true;
    }

    if (datePartValid == true && timePartValid == true) {
      //update model value
      let dateWithoutSeconds = date.startOf('minute');
      //date.set('second', 0);
      date = dateWithoutSeconds;//remove seconds and miliseconds from date
      this.model.setValue(date);

      this.changeEvent(date);

    } else {
      //set model value to null
      this.model.setValue(null);

      this.SoftErrors = [];
      this.HardErrors = [];
    }

    this.model.updateValueAndValidity();

    console.log(this.model);
  }

  filter(pickerPart) {
    switch (pickerPart) {
      case 'month':
        this.filteredMonths = this.filterByValue(this.months, this.monthModel);
        break;
      case 'day':
        this.filteredDays = this.filterByValue(this.days, this.dayModel);
        break;
      case 'year':
        this.filteredYears = this.filterByValue(this.years, this.yearModel);
        break;
      case 'hour':
        this.filteredHours = this.filterByValue(this.hours, this.hourModel);
        break;
      case 'minute':
        this.filteredMinutes = this.filterByValue(this.minutes, this.minuteModel);
        break;
      case 'ampm':
        this.filteredAmPms = this.filterByValue(this.ampms, this.ampmModel);
        break;
    }
  }

  filterByValue(array, string) {
    return array.filter(o => {
      if (o != null)
        return o.behindFilter.toString().toLowerCase().includes(string.toLowerCase())
    });
  }
  ////////////////////////////////////////

  constructor(public dateTimeService: DateTimeFormatService, public appService: AppService, private dataService: DataService) {

  }

  doSomething() {
    //console.log('child picker function called');
    this.customOnChange.emit();
  }


  //AllDateValidations: [];
  DateValueToValidate: any;
  DateFieldName: string;
  DateSectionName: string;
  DateOtherComparsion: string;
  //DateBehind: FormControl;
  //DateFront: FormControl;
  SpecialValidation: string;

  FormControl: FormControl;
  SoftErrors: any[];
  HardErrors: any[];


  checkValidations(data: any, fieldName: string, sectionName: string, formControl: FormControl, otherDateComparsion: string) {

    
    this.DateValueToValidate = data;
    //this.DateFieldName = fieldName;
    //this.DateSectionName = sectionName;
    this.FormControl = formControl;
    //this.DateOtherComparsion = otherDateComparsion;
    //this.DateBehind = behindDate;
    //this.DateFront = frontDate;

    this.validateDate();
  }

  validateDate = () => {

    this.SoftErrors = [];
    this.HardErrors = [];
    var allValidationsForField = [];
    allValidationsForField = this.appService.lodashFilterBy(this.appService.AllDateValidations, 'KeyId', this.id);//filter by field name
    allValidationsForField = this.appService.lodashFilterBy(allValidationsForField, 'Section', this.sectionName);//filter by section name
    //console.log(allValidationsForField);
    var dateToCompare = this.appService.toMomentDate(this.DateValueToValidate);//.format(this.dateTimeService.format);

    if (dateToCompare == null) {
      return null;
    }

    if (allValidationsForField.length > 0) {
      for (var i = 0; i <= allValidationsForField.length - 1; i++) {

        var compareAgainstDate = this.getCompareAgainstDateValue(allValidationsForField[i]);//.format(this.dateTimeService.format);
        if (compareAgainstDate != null) {
          switch (allValidationsForField[i].Operation) {
            case '>':

              //if (this.DateValueToValidate > this.appService.toMomentDate(new Date(compareAgainstDate))) {
              if (dateToCompare > compareAgainstDate) {

                if (allValidationsForField[i].TypeOfValidation == 'Hard') {
                  this.HardErrors.push({ ErrorMessage: allValidationsForField[i].ErrorMessage, typeOfValidation: allValidationsForField[i].TypeOfValidation })
                }

                if (allValidationsForField[i].TypeOfValidation == 'Soft') {

                  this.SoftErrors.push({ ErrorMessage: allValidationsForField[i].ErrorMessage, typeOfValidation: allValidationsForField[i].TypeOfValidation })
                }
              }

              break;
            case '>=':
              if (dateToCompare >= compareAgainstDate) {
                if (allValidationsForField[i].TypeOfValidation == 'Hard') {
                  this.HardErrors.push({ ErrorMessage: allValidationsForField[i].ErrorMessage, typeOfValidation: allValidationsForField[i].TypeOfValidation })
                }

                if (allValidationsForField[i].TypeOfValidation == 'Soft') {

                  this.SoftErrors.push({ ErrorMessage: allValidationsForField[i].ErrorMessage, typeOfValidation: allValidationsForField[i].TypeOfValidation })
                }
              }
              break;

            case '<':
              if (dateToCompare < compareAgainstDate) {
                if (allValidationsForField[i].TypeOfValidation == 'Hard') {
                  this.HardErrors.push({ ErrorMessage: allValidationsForField[i].ErrorMessage, typeOfValidation: allValidationsForField[i].TypeOfValidation })
                }

                if (allValidationsForField[i].TypeOfValidation == 'Soft') {
                  this.SoftErrors.push({ ErrorMessage: allValidationsForField[i].ErrorMessage, typeOfValidation: allValidationsForField[i].TypeOfValidation })
                }
              }
              break;
            case '<=':
              if (dateToCompare <= compareAgainstDate) {
                if (allValidationsForField[i].TypeOfValidation == 'Hard') {
                  this.HardErrors.push({ ErrorMessage: allValidationsForField[i].ErrorMessage, typeOfValidation: allValidationsForField[i].TypeOfValidation })
                }

                if (allValidationsForField[i].TypeOfValidation == 'Soft') {
                  this.SoftErrors.push({ ErrorMessage: allValidationsForField[i].ErrorMessage, typeOfValidation: allValidationsForField[i].TypeOfValidation })
                }
              }
              break;
            case '==':
              if (dateToCompare == compareAgainstDate) {
                if (allValidationsForField[i].TypeOfValidation == 'Hard') {
                  this.HardErrors.push({ ErrorMessage: allValidationsForField[i].ErrorMessage, typeOfValidation: allValidationsForField[i].TypeOfValidation })
                }

                if (allValidationsForField[i].TypeOfValidation == 'Soft') {
                  this.SoftErrors.push({ ErrorMessage: allValidationsForField[i].ErrorMessage, typeOfValidation: allValidationsForField[i].TypeOfValidation })
                }
              }
              break;
            default:
              return null;
          }
        }


          

        //} else {
        //}
      }

      //if (this.HardErrors.length > 0) {
      //  this.FormControl.setErrors({ hardError: true });
      //}
    }

    if (this.otherDateComparsion != null && this.otherDateComparsion != undefined) {
      var validationGroupArr = this.otherDateComparsion.split('|');

      for (i = 0; i <= validationGroupArr.length - 1; i++) {
        var validationObjectArr = validationGroupArr[i].split('-');
        //[0] table/object, [1] field name, [2] Hard/Soft, [3] comparison sign, [4] comparison part (min/hr/day/m/y), [5] number representing difference in (min/hr/day/m/y)

        var object = this.appService.getObjectToCompare(validationObjectArr[0]);
        if (object != undefined) {
          compareAgainstDate = object[validationObjectArr[1]];

          if (compareAgainstDate != undefined && compareAgainstDate != null) {
           // compareAgainstDate = this.appService.toMomentDate(compareAgainstDate);//convert to moment

            if (validationObjectArr[2] && validationObjectArr[3]) {

              if (validationObjectArr[4] && validationObjectArr[5]) {//comparing with (min/hr/day/m/y) and difference

              } else {//just comparing dates before/after/equal

                this.compareDates(validationObjectArr, dateToCompare, compareAgainstDate);

              }


            }
          }
        }

      }
    }

    var currentDate = this.appService.toMomentCurrentDate();
    
    if (dateToCompare > currentDate) {
      this.HardErrors.push({ ErrorMessage: 'This date cant be in future', typeOfValidation: 'Hard' })
    }

    if (this.HardErrors.length > 0) {
      //console.log('bingo');//need to figure out why hard error is not set when comparing against otherDateComparsion

      if (this.appService.UserProfile.DatePickerType == 2) {
        setTimeout(() => {//TimeOut is required for form validity to be properly reflected to parent
          this.FormControl.setErrors({ hardError: true });
        }, 0);
      } else {
        this.FormControl.setErrors({ hardError: true });
      }
    }

  }

  getMinMaxHardSoftValues(): any {
    return this.appService.AllDateValidations;
  }

  //changeEvent(event: MatDatepickerInputEvent<Date>) {
  changeEvent(data) {
    if (this.appService.UserProfile.DatePickerType == 2) {//Legacy DatePicker
      setTimeout(() => {//TimeOut is required for form validity to be properly reflected to parent
        //        console.log(this.id);
        this.customOnChange.emit();
      }, 0);
    } else {
      this.customOnChange.emit();
    }
    //  this.onSuggestTest(this.model, this.dateBehind);
    //  this.onSuggestTest(this.model, this.dateFront);
    this.checkValidations(data, this.id, this.sectionName, this.model, this.otherDateComparsion);
  }

  ngAfterViewInit() {
    this.customOnChange.emit();//this line is a must here, in order to sucessfuly validate all run details
   // this.checkValidations(this.model.value, this.id, this.sectionName, this.model, this.otherDateComparsion, this.dateBehind, this.dateFront, this.specialValidation);

    //need to figure out how to call this method when rundetail is deleted
    
    //setTimeout(() => {
    //  this.checkValidations(this.model.value, this.id, this.setionName, this.model);
    //}, 500);//timeout with 500 at least must be set here in order for this to work
  }
  ngOnInit() {
    /* OLD REGISTRY PICKER */
    if (this.pickerType == 'calendar') {
      this.showTimePicker = false;
    } else if (this.pickerType == 'timer') {
      this.showDatePicker = false;
    }
    if (this.appService.UserProfile.DatePickerType == 2) {
      var tmpX = this.appService.getArrayOfNumbersBetween(1920, new Date().getFullYear());
      tmpX = this.appService.lodashSortBy(tmpX, 'id').reverse();
      for (var i = 0; i < tmpX.length; i++) {
        this.years.push({ name: '' + tmpX[i] + '', id: tmpX[i], behindFilter: tmpX[i] });
      }

      if (this.dateTimeService.twelveHourFormat == true) {
        this.showAmPmPicker = true;
        this.hours = [
          { name: 'Hour', id: '', behindFilter: 'z' },
          { name: '01', id: 1, behindFilter: '1' },
          { name: '02', id: 2, behindFilter: '2' },
          { name: '03', id: 3, behindFilter: '3' },
          { name: '04', id: 4, behindFilter: '4' },
          { name: '05', id: 5, behindFilter: '5' },
          { name: '06', id: 6, behindFilter: '6' },
          { name: '07', id: 7, behindFilter: '7' },
          { name: '08', id: 8, behindFilter: '8' },
          { name: '09', id: 9, behindFilter: '9' },
          { name: '10', id: 10, behindFilter: '10' },
          { name: '11', id: 11, behindFilter: '11' },
          { name: '12', id: 12, behindFilter: '12' },
        ];
        this.filteredHours = this.hours;
      }

      if (this.model.value != null && this.model.value != '') {
        var date = this.appService.toMomentDate(this.model.value);

        this.selectedMonth = this.appService.lodashFindBy(this.months, 'id', date.month());
        this.selectedDay = this.appService.lodashFindBy(this.days, 'id', date.date());
        this.selectedYear = this.appService.lodashFindBy(this.years, 'id', date.year());
        if (this.showAmPmPicker == true) {
          if (date.hour() < 12) {
            if (date.hour() == 0) {
              this.selectedHour = this.appService.lodashFindBy(this.hours, 'id', 12);
            } else {
              this.selectedHour = this.appService.lodashFindBy(this.hours, 'id', date.hour());
            }
            this.selectedAmPm = this.ampms[1];
          } else {
            if (date.hour() > 12) {
              this.selectedHour = this.appService.lodashFindBy(this.hours, 'id', date.hour() - 12);
            } else {
              this.selectedHour = this.appService.lodashFindBy(this.hours, 'id', 12);
            }
            this.selectedAmPm = this.ampms[2];
          }

        } else {
          this.selectedHour = this.appService.lodashFindBy(this.hours, 'id', date.hour());
        }
        this.selectedMinute = this.appService.lodashFindBy(this.minutes, 'id', date.minute());

        this.checkValidations(this.model.value, this.id, this.sectionName, this.model, this.otherDateComparsion);

      }
    } else {
      this.checkValidations(this.model.value, this.id, this.sectionName, this.model, this.otherDateComparsion);
    }
  }

  compareDatesWithTimeDifference(object: any, dateToCompare: any, compareAgainstDate: any) {
    var compareAgainstDateMoment = this.appService.toMomentDate(compareAgainstDate);
    var errorExist = false;
    switch (object[3]) {
      case '>':
        if (dateToCompare > compareAgainstDateMoment) {
          errorExist = true;
        }

        break;
      case '>=':
        if (dateToCompare >= compareAgainstDateMoment) {
          errorExist = true;
        }
        break;

      case '<':
        if (dateToCompare < compareAgainstDateMoment) {
          errorExist = true;
        }
        break;
      case '<=':
        if (dateToCompare <= compareAgainstDateMoment) {
          errorExist = true;
        }
        break;
      case '==':
        if (dateToCompare == compareAgainstDateMoment) {
          errorExist = true;
        }
        break;

    }
    if (errorExist == true) {

      var formatedDate = compareAgainstDateMoment.locale(this.dateTimeService.locale).format(this.dateTimeService.format);
      if (object[2] == 'Hard') {
        this.HardErrors.push({ ErrorMessage: this.appService.getDateErrorMessage(object[1], object[3]) + ' ' + formatedDate, typeOfValidation: 'Hard' })
      } else if (object[2] == 'Soft') {
        this.SoftErrors.push({ ErrorMessage: this.appService.getDateErrorMessage(object[1], object[3]) + ' ' + formatedDate, typeOfValidation: 'Soft' });
      }

    }

  }

  compareDates(object: any, dateToCompare: any, compareAgainstDate: any) {
    var compareAgainstDateMoment = this.appService.toMomentDate(compareAgainstDate);
    var errorExist = false;
    switch (object[3]) {
      case '>':
        if (dateToCompare > compareAgainstDateMoment) {
          errorExist = true;
        }
        break;
      case '>=':
        if (dateToCompare >= compareAgainstDateMoment) {
          errorExist = true;
        }
        break;
      case '<':
        if (dateToCompare < compareAgainstDateMoment) {
          errorExist = true;
        }
        break;
      case '<=':
        if (dateToCompare <= compareAgainstDateMoment) {
          errorExist = true;
        }
        break;
      case '==':
        if (dateToCompare == compareAgainstDateMoment) {
          errorExist = true;
        }
        break;
    }

    if (errorExist == true) {

      var formatedDate = compareAgainstDateMoment.locale(this.dateTimeService.locale).format(this.dateTimeService.format);

      if (object[2] == 'Hard') {
        this.HardErrors.push({ ErrorMessage: this.appService.getDateErrorMessage(object[1], object[3]) + ' ' + formatedDate, typeOfValidation: 'Hard' })
      } else if (object[2] == 'Soft'){
        this.SoftErrors.push({ ErrorMessage: this.appService.getDateErrorMessage(object[1], object[3]) + ' ' + formatedDate, typeOfValidation: 'Soft' });
      }

    }


  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.appService.toMomentDate(this.model.value);
    if (ctrlValue != null) {//07-27-2023
      ctrlValue.year(normalizedYear.year);
      this.model.setValue(ctrlValue);
    }
  }

  chosenMonthHandler(normalizedMonth: Moment) {//commented out because it resets date picker when month is chosen and day is not yet selected
    //const ctrlValue = this.appService.toMomentDate(this.model.value);
    //if (ctrlValue != null) {//07-27-2023
    //  ctrlValue.month(normalizedMonth.month);
    //  this.model.setValue(ctrlValue);
    //}
  }

  chosenDayHandler(normalizedDay: Moment, datepicker: OwlDateTimeComponent<Moment>) {
    const ctrlValue = this.appService.toMomentDate(this.model.value);
    if (ctrlValue != null) {//07-27-2023
      ctrlValue.day(normalizedDay.day);
      this.model.setValue(ctrlValue);
    }
    datepicker.close();
  }

  resetDatePickerValue() {
    //this.dateTimeService.testCallFromDatePicker();
    if (this.appService.UserProfile.DatePickerType == 2) {
      this.monthModel = null;
      this.dayModel = null;
      this.yearModel = null;
      this.hourModel = null;
      this.minuteModel = null;
      this.ampmModel = null;

      this.selectedMonth = {
        name: 'Month', id: '', behindFilter: 'z'
      };
      this.selectedDay = {
        name: 'Day', id: '', behindFilter: 'z'
      };
      this.selectedYear = {
        name: 'Year', id: '', behindFilter: 'z'
      };
      this.selectedHour = {
        name: 'Hour', id: '', behindFilter: 'z'
      };
      this.selectedMinute = {
        name: 'Minute', id: '', behindFilter: 'z'
      };
      this.selectedAmPm = {
        name: 'AM/PM', id: '', behindFilter: 'z'
      };

      this.model.setValue(null);
      this.SoftErrors = [];
      this.HardErrors = [];
    } else {
      this.model.setValue(null);
    }
  }
  setDatePickerDdValue(model) {
    this.model = model;

    if (this.appService.UserProfile.DatePickerType == 2) {
      if (this.model.value != null && this.model.value != '') {
        var date = this.appService.toMomentDate(this.model.value);

        this.selectedMonth = this.appService.lodashFindBy(this.months, 'id', date.month());
        this.selectedDay = this.appService.lodashFindBy(this.days, 'id', date.date());
        this.selectedYear = this.appService.lodashFindBy(this.years, 'id', date.year());
        if (this.showAmPmPicker == true) {
          if (date.hour() < 12) {
            if (date.hour() == 0) {
              this.selectedHour = this.appService.lodashFindBy(this.hours, 'id', 12);
            } else {
              this.selectedHour = this.appService.lodashFindBy(this.hours, 'id', date.hour());
            }
            this.selectedAmPm = this.ampms[1];
          } else {
            if (date.hour() > 12) {
              this.selectedHour = this.appService.lodashFindBy(this.hours, 'id', date.hour() - 12);
            } else {
              this.selectedHour = this.appService.lodashFindBy(this.hours, 'id', 12);
            }
            this.selectedAmPm = this.ampms[2];
          }

        } else {
          this.selectedHour = this.appService.lodashFindBy(this.hours, 'id', date.hour());
        }
        this.selectedMinute = this.appService.lodashFindBy(this.minutes, 'id', date.minute());

        this.checkValidations(this.model.value, this.id, this.sectionName, this.model, this.otherDateComparsion);

      }
    }
  }	

  getCompareAgainstDateValue(compareAgainstObject: any) {
    var dateString = null;
    switch (compareAgainstObject.ParentObject) {
      case 'Run':
        dateString = this.appService.Run[compareAgainstObject.CompareAgainstField];
        break;
      case 'ECPR2020Addendum':
        dateString = this.appService.Run.ECPR2020Addenda[0][compareAgainstObject.CompareAgainstField];
        break;
      case 'Cardiac2022Addendum':
        dateString = this.appService.Run.Cardiac2022Addenda[0][compareAgainstObject.CompareAgainstField];
        break;
      case 'Patient':
        dateString = this.appService.Patient[compareAgainstObject.CompareAgainstField];
        break;
    }

    if (dateString == null) {
      return null;
    } else {
      var momentDate = this.appService.toMomentDate(dateString);
      if (compareAgainstObject.IncreaseDecreaseDateValue != null && compareAgainstObject.IncreaseDecreaseMeasureUnit != null) {
        return momentDate.add(compareAgainstObject.IncreaseDecreaseDateValue, compareAgainstObject.IncreaseDecreaseMeasureUnit);
      } else {
        return momentDate;
      }
    }
   
  }
}
