<div [hidden]="appService.isLoading | async" style="height: 1000px; overflow-y: scroll; ">
  <br /><br /> <br />
  <div *ngIf="!systemVersion.IsOffline && showLoginButton" style="border: 5px solid #3F51B5; width: fit-content; border-radius: 15px; padding: 3%; margin: auto;">
    <form class="example-form" autocomplete="off">
      <img style="width: 200px;" class="" src="../assets/images//elso-logo-big.png">
      <br />
      <br />
      <br />
      <div *ngIf="showLoginButton">
        <!--<mat-checkbox id="testXML" value="true" name="testXML" [(ngModel)]="testXML">Test XML Import</mat-checkbox>
        <br /><br />-->
        <button type="button" color="primary" mat-raised-button (click)="loginAuth0()">Login</button>
      </div>
    </form>
  </div>

  <mat-spinner *ngIf="!showLoginButton"
               style="position: absolute; top: 0; z-index: 5000; top: 40%; left: 48.2%;"></mat-spinner>
</div>


