////import { Injectable } from "@angular/core";
////import { BehaviorSubject } from "rxjs";

////@Injectable()
////export class LoadingService {
////  private isLoading$$ = new BehaviorSubject<boolean>(false);
////  isLoading$ = this.isLoading$$.asObservable();

////  setLoading(isLoading: boolean) {
////    this.isLoading$$.next(isLoading);
////  }
////}


import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';



@Injectable()
export class LoadingService {
  public isLoading = new BehaviorSubject(false);

  constructor() { }
}


