import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'report-runs-by-year',
  templateUrl: './reportRunsByYear.component.html',
})

export class ReportRunsByYear {
  @Input() public reportData: any;



  constructor() {
    //console.log('myCustomComponent');

  }


  OnInit() {
    console.log(this.reportData);
  }
}
