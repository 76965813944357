<div *ngIf="router.url == '/login' || router.url == '/logintestxml' || router.url == '/sloginE0E52F31B39847C3A8C' || router.url == '/logout'" class="content" style="text-align: center;">
  <mat-spinner *ngIf="appService.isLoading | async"
               style="position: absolute; top: 0; z-index: 5000; top: 40%; left: 48.2%;"></mat-spinner>
  <router-outlet></router-outlet>
</div>
<div *ngIf="router.url != '/login' && router.url != '/logintestxml' && router.url != '/sloginE0E52F31B39847C3A8C' && router.url != '/logout'">
  <mat-toolbar role="toolbar" class="main-header mat-elevation-z1 sticky-header" color="primary">
    <mat-toolbar-row id="headerMainTopHolder">
      <div class="headerBlock" id="leftMenuHolder">
        <button mat-button (click)="sidenav1.toggle()" id="mainNav" class="toggle-Mbtn new--item">
          <i class="material-icons color-white">menu</i>
        </button>
        <span>ELSO Registry</span>
        <br />
      </div>
      <div class="headerBlock" id="centerMenuHolder">
        <div class="elso-logo">
          <img class="" src="../assets/images//elso_logo.png" (click)="changeMainRoute('home')" style="cursor: pointer">
        </div>
      </div>
      <div class="headerBlock" id="rightMenuHolder">
        <p *ngIf="appService.userCenters.length == 0" style="color:white; display:inline;" class="designation">
          {{appService.UserProfile.CenterName}}
        </p>
        <form class="center-form" *ngIf="appService.userCenters.length > 0">
          <div *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')" style="display:inline;">
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Center:</mat-label>
              <input type="text" id="centerSearchList" placeholder="Please select" matInput (input)="filterCenters($event)" [(ngModel)]="appService.centerQuery" name="centerQuery" [matAutocomplete]="auto">
              <mat-autocomplete hideSingleSelectionIndicator (optionSelected)="onSelectionChangeCenter($event)" #auto="matAutocomplete" [displayWith]="displayFnCenter">
                <mat-option *ngFor="let center of appService.filteredCenters" [value]="center">
                  {{center.CenterNo}} - {{center.Name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <!--for all other users that have multiple centers-->
          <div *ngIf="!appService.isUserInRole('Host User') && !appService.isUserInRole('Host User - Registry Only')" style="display:inline;">
            <mat-form-field class="select-form-field" id="mainCentersList">
              <mat-label>Center:</mat-label>
              <mat-select name="Center" placeholder="Please select" [(ngModel)]="appService.UserProfile.CenterId" panelClass="panel-custom-width"
                          (selectionChange)="onSelectionChange()">
                <mat-option *ngFor="let center of appService.userCenters" [value]="center.CenterId">
                  {{center.CenterNo}} - {{center.Name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
        <div style="display:inline-block">
          <button style="float:right;" *ngIf="appService.Run" mat-button (click)="sidenav2.toggle()"
                  class="toggle-Mbtn new--item" id="runSidenav">
            <span>{{appService.Run.Patient.UniqueId}}</span> &nbsp;
            <i class="material-icons color-white">local_hospital</i>
          </button>
          <button class="account-btn" *ngIf="router.url != '/login' && router.url != '/logintestxml' && router.url != '/sloginE0E52F31B39847C3A8C' && router.url != '/logout'" mat-icon-button
                  [matMenuTriggerFor]="userMenu">
            <mat-icon>account_circle</mat-icon>
          </button>
          <span [matMenuTriggerFor]="userMenu" style="cursor: pointer; position: relative; top: 3px;">{{appService.UserProfile.UserName}}</span>
        </div>
        <div>
          <p id="pleaseEmail">If you have questions or need any help with the Registry, please email us at <a href="mailto:RegistrySupport@elso.org" style="color: red;">RegistrySupport&#64;elso.org</a></p>
        </div>
      </div>
      <mat-menu #userMenu="matMenu" xPosition="before" yPosition="below">
        <div style="text-align: center; margin-top: 5px;">
          <span>User roles: </span><br />
          <img *ngIf="(appService.isUserInRole('ECLS Center Data Manager') || appService.isUserInRole('ECLS Center Data Entry') || appService.isUserInRole('ECLS Center Data Viewer')) && !appService.isUserInRole('ECLS Center Administrator')" src="../assets/images//CenterBadge.png" style="width: 95px; margin-left: 5px;" />
          <img *ngIf="appService.isUserInRole('ECLS Center Administrator')" src="../assets/images//CenterAdminBadge.png" style="width: 95px; margin-left: 5px;" />
          <img *ngIf="appService.isUserInRole('Individual Member')" src="../assets/images//IndividualBadge.png" style="width: 95px; margin-left: 5px;" />
          <img *ngIf="appService.isUserInRole('Corporate Member Administrator') || appService.isUserInRole('Corporate Member - Regular User')" src="../assets/images//CorporateBadge.png" style="width: 95px; margin-left: 5px;" />
        </div>
        <mat-divider></mat-divider>
        <button routerLink="settings" mat-menu-item>Settings</button>
        <mat-divider *ngIf="appService.isUserInRole('ECLS Center Data Manager') || appService.isUserInRole('ECLS Center Data Entry') || appService.isUserInRole('ECLS Center Administrator') || appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')"></mat-divider>
        <button *ngIf="appService.isUserInRole('ECLS Center Data Manager') || appService.isUserInRole('ECLS Center Data Entry') || appService.isUserInRole('ECLS Center Administrator') || appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')" routerLink="centerspecificdevices" mat-menu-item>Center Specific Devices</button>
        <mat-divider></mat-divider>
        <button (click)="logout()" mat-menu-item>Logout</button>
        <mat-divider></mat-divider>

      </mat-menu>
    </mat-toolbar-row>
    <mat-toolbar-row class="second-row">
      <button [class.activeSectionRoute]="activeLink.includes('home')" mat-flat-button
              class="menu-button flex-item" (click)="changeMainRoute('home')" color="primary">
        <span>Registry Home</span>
      </button>
      <mat-divider></mat-divider>
      <button color="primary" [class.activeSectionRoute]="activeLink.includes('allruns')" mat-flat-button
              class="menu-button flex-item" (click)="changeMainRoute('allruns')">
        <span>ECLS ALL Runs</span>
      </button>
      <mat-divider></mat-divider>
      <button color="primary" mat-flat-button class="menu-button flex-item" [matMenuTriggerFor]="reportsMenu">
        <span>Reports</span>
        <mat-icon>arrow_right</mat-icon>
      </button>
      <mat-menu #reportsMenu="matMenu" xPosition="before" yPosition="below">
        <button mat-menu-item (click)="patientAllReport()">All Patient List</button>
        <button mat-menu-item (click)="changeMainRoute('custompatientsreport')">Custom Patient List</button>
        <!--<button mat-menu-item>Benchmarking</button>
        <button mat-menu-item (click)="changeMainRoute('internationalreport')">International Summary</button>
        <button mat-menu-item>Regional Summary</button>
        <button mat-menu-item>Center Specific Summary</button>
        <mat-divider></mat-divider>
        <button mat-menu-item [matMenuTriggerFor]="reportsTrendSubMenu">Trend Reports</button>
        <mat-menu #reportsTrendSubMenu="matMenu">
          <button mat-menu-item>(All) Trend Report</button>
          <button mat-menu-item>Center Trend Report</button>
          <button mat-menu-item>Region Trend Report</button>
        </mat-menu>
        <button mat-menu-item [matMenuTriggerFor]="reportsComplicationsSubMenu">
          Trend Complication Reports
        </button>
        <mat-menu #reportsComplicationsSubMenu="matMenu" xPosition="before" yPosition="below">
          <button mat-menu-item>(All) Trend Complications Report</button>
          <button mat-menu-item>Center Trend Complications Report</button>
          <button mat-menu-item>Region Trend Complications Report</button>
        </mat-menu>-->
      </mat-menu>
      <mat-divider *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')"></mat-divider>
      <button *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')" color="primary" [class.activeSectionRoute]="activeLink.includes('runpatientdeletion')"
              mat-flat-button class="menu-button flex-item" (click)="changeMainRoute('runpatientdeletion')">
        <span>Deletion</span>
      </button>
      <!--<mat-divider *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')"></mat-divider>
      <button *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')" color="primary" [class.activeSectionRoute]="activeLink.includes('settings')" mat-flat-button
              class="menu-button flex-item" (click)="changeMainRoute('settings')">
        <span>Administration</span>
      </button>-->
      <mat-divider></mat-divider>
      <button [class.activeSectionRoute]="activeLink.includes('rundatadump')" (click)="changeMainRoute('rundatadump')" color="primary" mat-flat-button class="menu-button flex-item">
        <span>Data Export</span>
      </button>
      <!--<mat-divider *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')"></mat-divider>
      <button *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')" [class.activeSectionRoute]="activeLink.includes('ascenduserrequestdatadump')" (click)="changeMainRoute('rundatadump')" color="primary" mat-flat-button class="menu-button flex-item">
        <span>Ascend User Request Report</span>
      </button>-->
     <mat-divider *ngIf="appService.UserProfile.CanEditRunPatient && !appService.CenterIsXML"></mat-divider>
      <button *ngIf="appService.UserProfile.CanEditRunPatient && !appService.CenterIsXML" color="primary" mat-flat-button class="menu-button flex-item"
              [class.activeSectionRoute]="activeLink.includes('addpatient')"
              (click)="changeMainRoute('/patient/addpatient/0')">
        <span>Add New Patient</span>
      </button>
      <mat-divider *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')"></mat-divider>
      <button *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')" color="primary" mat-flat-button class="menu-button flex-item" [matMenuTriggerFor]="equipmentMenu">
        <span>Equipment Master</span>
        <mat-icon>arrow_right</mat-icon>
      </button>
      <mat-menu *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')" #equipmentMenu="matMenu" xPosition="before" yPosition="below">
        <button mat-menu-item (click)="dataDumpMembraneLungs();">Membrane Lungs List</button>
        <button mat-menu-item (click)="dataDumpBloodPumps();">Blood Pumps List</button>
        <button mat-menu-item (click)="dataDumpHemofilters();">Hemofilter List</button>
        <button mat-menu-item (click)="dataDumpHeatExchangers();">Heat Exchangers List</button>
        <button mat-menu-item (click)="dataDumpTemperatureRegulations();">Temperature Regulations List</button>
        <button mat-menu-item (click)="dataDumpCannulas();">Cannulas List</button>
        <button mat-menu-item (click)="dataDumpManufacturers();">Manufacturers List</button>
        <button mat-menu-item (click)="dataDumpEquipmentMaster();">Equipment Master List</button>
      </mat-menu>
      <!--<mat-divider *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')"></mat-divider>
      <button *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')" color="primary" mat-flat-button class="menu-button flex-item">
        <span>Soft & Hard Ranges</span>
      </button>-->
      <mat-divider *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')"></mat-divider>
      <button *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')" color="primary" [class.activeSectionRoute]="activeLink.includes('scandevice')" mat-flat-button
              class="menu-button flex-item" (click)="changeMainRoute('scandevice')">
        <span>Scan Device</span>
      </button>
      <!--<mat-divider *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')"></mat-divider>
      <button *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')" color="primary" mat-flat-button class="menu-button flex-item">
        <span>Unlock Data Trail</span>
      </button>-->
      <!--<mat-divider *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')"></mat-divider>
      <button *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')" color="primary" mat-flat-button class="menu-button flex-item">
        <span>Gudid Devices Filter</span>
      </button>-->
      <mat-divider *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only') || appService.isUserInRole('Center User - Import XML') || appService.isUserInRole('Multi Center XML Import')"></mat-divider>
      <button color="primary" mat-flat-button class="menu-button flex-item" [matMenuTriggerFor]="xmlImportMenu">
        <span>XML Import</span>
        <mat-icon>arrow_right</mat-icon>
      </button>
      <mat-divider></mat-divider>
      <mat-menu *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only') || appService.isUserInRole('Center User - Import XML') || appService.isUserInRole('Multi Center XML Import')" #xmlImportMenu="matMenu" xPosition="before" yPosition="below">
        <button mat-menu-item [class.activeSectionRoute]="activeLink.includes('xmlimportlive')"
                (click)="changeMainRoute('xmlimportlive')">
          XML Live Import
        </button>
        <button mat-menu-item [class.activeSectionRoute]="activeLink.includes('xmlimporttest')"
                (click)="changeMainRoute('xmlimporttest')">
          XML File Test
        </button>
      </mat-menu>
      <mat-divider></mat-divider>

    </mat-toolbar-row>
    <mat-toolbar-row class="third-row" *ngIf="appService.Run">
      <div *ngIf="appService.isLoading | async" style="width: 100%; display: block; height: 50px; position: absolute; z-index: 100;">
        <!--this div will display over menu buttons to prevent user from moving to other Run sections when data is loading-->
      </div>
      <mat-divider></mat-divider>
      <button color="primary" mat-flat-button class="menu-button flex-item"
              [class.activeSectionRoute]="activeLink.includes('runinfo')" (click)="changeRunRoute('runinfo');">
        <span>Run Info</span>
      </button>
      <mat-divider *ngIf="appService.Run.TransferFromCenter != 1 && appService.Run.TransferFromCenter != 2"></mat-divider>
      <button color="primary" mat-flat-button class="menu-button flex-item"
              *ngIf="appService.Run.TransferFromCenter != 1 && appService.Run.TransferFromCenter != 2"
              [class.activeSectionRoute]="activeLink.includes('preeclsassessment')"
              (click)="changeRunRoute('preeclsassessment');">
        <span>Pre ECLS Assessment</span>
      </button>
      <mat-divider></mat-divider>
      <button color="primary" mat-flat-button class="menu-button flex-item"
              [class.activeSectionRoute]="activeLink.includes('preeclssupport')"
              (click)="changeRunRoute('preeclssupport');">
        <span>Pre ECLS Support</span>
      </button>
      <mat-divider></mat-divider>
      <button color="primary" mat-flat-button class="menu-button flex-item"
              [class.activeSectionRoute]="activeLink.includes('eclsassessment') && !activeLink.includes('preeclsassessment')"
              (click)="changeRunRoute('eclsassessment');">
        <span>ECLS Assessment</span>
      </button>
      <mat-divider></mat-divider>
      <button color="primary" mat-flat-button class="menu-button flex-item"
              [class.activeSectionRoute]="activeLink.includes('modes')" (click)="changeRunRoute('modes');">
        <span>Modes</span>
      </button>
      <mat-divider></mat-divider>
      <button color="primary" mat-flat-button class="menu-button flex-item"
              [class.activeSectionRoute]="activeLink.includes('cannulations')"
              (click)="changeRunRoute('cannulations');">
        <span>Cannulations</span>
      </button>
      <mat-divider></mat-divider>
      <button color="primary" mat-flat-button class="menu-button flex-item"
              [class.activeSectionRoute]="activeLink.includes('equipment')" (click)="changeRunRoute('equipment');">
        <span>Equipment</span>
      </button>
      <mat-divider></mat-divider>
      <button color="primary" mat-flat-button class="menu-button flex-item"
              [class.activeSectionRoute]="activeLink.includes('diagnoses')" (click)="changeRunRoute('diagnoses');">
        <span>Diagnoses</span>
      </button>
      <mat-divider></mat-divider>
      <button color="primary" mat-flat-button class="menu-button flex-item"
              [class.activeSectionRoute]="activeLink.includes('procedures')" (click)="changeRunRoute('procedures');">
        <span>Procedures</span>
      </button>
      <mat-divider></mat-divider>
      <button color="primary" mat-flat-button class="menu-button flex-item"
              [class.activeSectionRoute]="activeLink.includes('complications')"
              (click)="changeRunRoute('complications');">
        <span>Complications</span>
      </button>
      <mat-divider></mat-divider>
      <button color="primary" mat-flat-button class="menu-button flex-item"
              [class.activeSectionRoute]="activeLink.includes('infections')" (click)="changeRunRoute('infections');">
        <span>Infections</span>
      </button>
      <mat-divider></mat-divider>
      <button color="primary" mat-flat-button class="menu-button flex-item"
              [class.activeSectionRoute]="activeLink.includes('outcome')" (click)="changeRunRoute('outcome');">
        <span>Outcome</span>
      </button>
      <mat-divider></mat-divider>
      <button color="primary" mat-flat-button class="menu-button flex-item"
              [class.activeSectionRoute]="activeLink.includes('addenda')" (click)="changeAddendaRoute('main');">
        <span>Addenda</span>
      </button>
      <mat-divider></mat-divider>
      <button color="primary" mat-flat-button class="menu-button flex-item"
              [class.activeSectionRoute]="activeLink.includes('submission')" (click)="changeRunRoute('submission');">
        <span>Submission</span>
      </button>
    </mat-toolbar-row>

  </mat-toolbar>


  <mat-sidenav-container>
    <mat-sidenav id="mainMenuSideNav" autoFocus="false" #sidenav1 mode="over" position="start" role="navigation" class="mat-elevation-z8" style="height: fit-content">
      <!--<img class="" src="../assets/images//elso_logo.png">
      <h4 class="name">John Smith user</h4>
      <p class="designation">{{appService.UserProfile.CenterName}}</p>-->
      <mat-divider></mat-divider>
      <button [class.activeSectionRoute]="activeLink.includes('home')" mat-button class="menu-button"
              (click)="changeMainRoute('home')">
        <span>Registry Home</span>
      </button>
      <mat-divider></mat-divider>
      <button [class.activeSectionRoute]="activeLink.includes('allruns')" mat-button class="menu-button"
              (click)="changeMainRoute('allruns')">
        <span>ALL Runs</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-button class="menu-button" [matMenuTriggerFor]="reportsMenu">
        <span>Reports</span>
        <mat-icon>arrow_right</mat-icon>
      </button>
      <mat-menu #reportsMenu="matMenu" xPosition="before" yPosition="below">
        <button mat-menu-item (click)="patientAllReport()">All Patient List</button>
        <!--<button mat-menu-item (click)="changeMainRoute('custompatientsreport')">Custom Patient List</button>-->
        <!--<button mat-menu-item (click)="sidenav1.toggle()">Benchmarking</button>
        <button mat-menu-item (click)="changeMainRoute('internationalreport')">International Summary</button>
        <button mat-menu-item (click)="sidenav1.toggle()">Regional Summary</button>
        <button mat-menu-item (click)="sidenav1.toggle()">Center Specific Summary</button>-->
        <!--<mat-divider></mat-divider>
        <button mat-menu-item [matMenuTriggerFor]="reportsTrendSubMenu">Trend Reports</button>
        <mat-menu #reportsTrendSubMenu="matMenu">
          <button mat-menu-item (click)="sidenav1.toggle()">(All) Trend Report</button>
          <button mat-menu-item (click)="sidenav1.toggle()">Center Trend Report</button>
          <button mat-menu-item (click)="sidenav1.toggle()">Region Trend Report</button>
        </mat-menu>
        <button mat-menu-item [matMenuTriggerFor]="reportsComplicationsSubMenu">
          Trend Complication Reports
        </button>
        <mat-menu #reportsComplicationsSubMenu="matMenu" xPosition="before" yPosition="below">
          <button mat-menu-item (click)="sidenav1.toggle()">(All) Trend Complications Report</button>
          <button mat-menu-item (click)="sidenav1.toggle()">Center Trend Complications Report</button>
          <button mat-menu-item (click)="sidenav1.toggle()">Region Trend Complications Report</button>
        </mat-menu>-->
      </mat-menu>
      <mat-divider *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')"></mat-divider>
      <button *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')" [class.activeSectionRoute]="activeLink.includes('runpatientdeletion')" mat-button
              class="menu-button" (click)="changeMainRoute('runpatientdeletion')">
        <span>Deletion</span>
      </button>
      <!--<mat-divider></mat-divider>
      <button [class.activeSectionRoute]="activeLink.includes('settings')" mat-button class="menu-button"
              (click)="changeMainRoute('settings')">
        <span>Administration</span>
      </button>-->
      <mat-divider></mat-divider>
      <button [class.activeSectionRoute]="activeLink.includes('rundatadump')" (click)="changeMainRoute('rundatadump')" mat-button class="menu-button">
        <span>Data Export</span>
      </button>
       <mat-divider *ngIf="appService.UserProfile.CanEditRunPatient && !appService.CenterIsXML"></mat-divider>
      <button *ngIf="appService.UserProfile.CanEditRunPatient && !appService.CenterIsXML" mat-button class="menu-button" [class.activeSectionRoute]="activeLink.includes('addpatient')"
              (click)="changeMainRoute('/patient/addpatient/0')">
        <span>Add New Patient</span>
      </button>
      <mat-divider *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')"></mat-divider>
      <button *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')" mat-button class="menu-button" [matMenuTriggerFor]="equipmentMenu">
        <span>Equipment Master</span>
        <mat-icon>arrow_right</mat-icon>
      </button>
      <mat-menu #equipmentMenu="matMenu" xPosition="before" yPosition="below">
        <button mat-menu-item (click)="dataDumpMembraneLungs();">Membrane Lungs List</button>
        <button mat-menu-item (click)="dataDumpBloodPumps();">Blood Pumps List</button>
        <button mat-menu-item (click)="dataDumpHemofilters();">Hemofilter List</button>
        <button mat-menu-item (click)="dataDumpHeatExchangers();">Heat Exchangers List</button>
        <button mat-menu-item (click)="dataDumpTemperatureRegulations();">Temperature Regulations List</button>
        <button mat-menu-item (click)="dataDumpCannulas();">Cannulas List</button>
        <button mat-menu-item (click)="dataDumpManufacturers();">Manufacturers List</button>
        <button mat-menu-item (click)="dataDumpEquipmentMaster();">Equipment Master List</button>
      </mat-menu>
      <mat-divider *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')"></mat-divider>
      <button *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')" mat-button class="menu-button" (click)="sidenav1.toggle()">
        <span>Soft & Hard Ranges</span>
      </button>
      <mat-divider *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')"></mat-divider>
      <button *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')" [class.activeSectionRoute]="activeLink.includes('scandevice')" mat-button class="menu-button"
              (click)="changeMainRoute('scandevice')">
        <span>Scan Device</span>
      </button>
      <mat-divider *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')"></mat-divider>
      <button *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')" mat-button class="menu-button" (click)="sidenav1.toggle()">
        <span>Unlock Data Trail</span>
      </button>
      <mat-divider *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')"></mat-divider>
      <button *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only')" mat-button class="menu-button" (click)="sidenav1.toggle()">
        <span>Gudid Devices Filter</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-button class="menu-button" [matMenuTriggerFor]="xmlImportMenu">
        <span>XML Import</span>
        <mat-icon>arrow_right</mat-icon>
      </button>
      <!--<mat-divider></mat-divider>-->
      <mat-menu #xmlImportMenu="matMenu" xPosition="before" yPosition="below">
        <button *ngIf="appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only') || appService.isUserInRole('Center User - Import XML') || appService.isUserInRole('Multi Center XML Import')" mat-menu-item [class.activeSectionRoute]="activeLink.includes('xmlimportlive')"
                (click)="changeMainRoute('xmlimportlive')">
          XML Live Import
        </button>
        <button mat-menu-item [class.activeSectionRoute]="activeLink.includes('xmlimporttest')"
                (click)="changeMainRoute('xmlimporttest')">
          XML File Test
        </button>
      </mat-menu>
      <!--<mat-divider></mat-divider>-->
    </mat-sidenav>

    <mat-sidenav id="runSideNav" autoFocus="false" #sidenav2 mode="over" position="end" role="navigation" class="mat-elevation-z8" style="height: fit-content">
      <!--<img class="" src="../assets/images//elso_logo.png">-->
      <div *ngIf="appService.Run">
        <!--<p class="name">Patient: {{appService.Run.Patient.UniqueId}}</p>
        <p>Run no: {{appService.Run.RunNo}}</p>
        <p>Age: {{appService.getFormatedStringFromDays(appService.Run.Age)}}</p>
        <p>
          Support type: {{appService.getEnumName(appService.Run.SupportType,this.appService.enumSupportTypes)}}
        </p>
        <p>Time on: {{appService.Run.TimeOn | dateFormat}}</p>
        <p *ngIf="appService.Run.TimeOff">Time off: {{appService.Run.TimeOff | dateFormat}}</p>-->
        <div *ngIf="appService.isLoading | async" style="width: 100%; display: block; height: 100%; position: absolute; z-index: 100;">
          <!--this div will display over menu buttons to prevent user from moving to other Run sections when data is loading-->
        </div>
        <mat-divider></mat-divider>
        <button [routerLink]="['/patient/patientinfo/' + appService.Run.PatientId]" mat-button
                class="menu-button" (click)="sidenav2.toggle();">
          <span>Patient Info</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-button class="menu-button" [class.activeSectionRoute]="activeLink.includes('runinfo')"
                (click)="changeRunRoute('runinfo');">
          <span>Run Info</span>
        </button>
        <mat-divider *ngIf="appService.Run.TransferFromCenter != 1 && appService.Run.TransferFromCenter != 2"></mat-divider>
        <button mat-button class="menu-button"
                *ngIf="appService.Run.TransferFromCenter != 1 && appService.Run.TransferFromCenter != 2"
                [class.activeSectionRoute]="activeLink.includes('preeclsassessment')"
                (click)="changeRunRoute('preeclsassessment');">
          <span>Pre ECLS Assessment</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-button class="menu-button"
                [class.activeSectionRoute]="activeLink.includes('preeclssupport')"
                (click)="changeRunRoute('preeclssupport');">
          <span>Pre ECLS Support</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-button class="menu-button"
                [class.activeSectionRoute]="activeLink.includes('eclsassessment') && !activeLink.includes('preeclsassessment')"
                (click)="changeRunRoute('eclsassessment');">
          <span>ECLS Assessment</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-button class="menu-button" [class.activeSectionRoute]="activeLink.includes('modes')"
                (click)="changeRunRoute('modes');">
          <span>Modes</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-button class="menu-button" [class.activeSectionRoute]="activeLink.includes('cannulations')"
                (click)="changeRunRoute('cannulations');">
          <span>Cannulations</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-button class="menu-button" [class.activeSectionRoute]="activeLink.includes('equipment')"
                (click)="changeRunRoute('equipment');">
          <span>Equipment</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-button class="menu-button" [class.activeSectionRoute]="activeLink.includes('diagnoses')"
                (click)="changeRunRoute('diagnoses');">
          <span>Diagnoses</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-button class="menu-button" [class.activeSectionRoute]="activeLink.includes('procedures')"
                (click)="changeRunRoute('procedures');">
          <span>Procedures</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-button class="menu-button" [class.activeSectionRoute]="activeLink.includes('complications')"
                (click)="changeRunRoute('complications');">
          <span>Complications</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-button class="menu-button" [class.activeSectionRoute]="activeLink.includes('infections')"
                (click)="changeRunRoute('infections');">
          <span>Infections</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-button class="menu-button" [class.activeSectionRoute]="activeLink.includes('outcome')"
                (click)="changeRunRoute('outcome');">
          <span>Outcome</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-button class="menu-button" [class.activeSectionRoute]="activeLink.includes('addenda')"
                (click)="changeAddendaRoute('main');">
          <span>Addenda</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-button class="menu-button" [class.activeSectionRoute]="activeLink.includes('submission')"
                (click)="changeRunRoute('submission');">
          <span>Submission</span>
        </button>
      </div>
    </mat-sidenav>

    <mat-sidenav-content id="sideNavMain">
      <!--<br />-->
      <fieldset *ngIf="appService.Run" class="patient-info">
        <legend>
          <span *ngIf="appService.Run.TestRun" style="font-size: 12px; color: white; background-color: #3F51B5; border-radius: 5px; padding: 5px;">Test</span>
          Run
        </legend>
        <div class="control-item">
          <p><b>Run no:</b> {{appService.Run.RunNo}}</p>
        </div>
        <div class="control-item">
          <p class="name"><b>Patient:</b> {{appService.Patient.UniqueId}}</p>
        </div>
        <div class="control-item">
          <p><b>Birthdate:</b> {{appService.Patient.Birthdate | dateFormat}}</p>
        </div>
        <div class="control-item">
          <p><b>Age:</b> {{appService.getFormatedStringFromDays(appService.Run.Age)}}</p>
        </div>
        <div class="control-item">
          <p>
            <b>Support type:</b> {{appService.getEnumName(appService.Run.SupportType, this.appService.enumSupportTypes)}}
          </p>
        </div>
        <div class="control-item">
          <p><b>ECLS Start Time:</b> {{appService.Run.TimeOn | dateFormat}}</p>
        </div>
        <div class="control-item" *ngIf="appService.Run.TimeOff">
          <p><b>ECLS Stop Time:</b> {{appService.Run.TimeOff | dateFormat}}</p>
        </div>
        <div class="control-item">
          <p><b>To edit Start or Stop time, click on the Modes & Cannulations tab above.</b></p>
        </div>
        <!--<br>-->
        <div>
          <div style="display: inline-block;">
            <button mat-button color="primary"
                    [routerLink]="['/patient/patientinfo/' + appService.Run.PatientId]">
              <i class="material-icons color-white">badge</i>
              Patient Information
            </button>
          </div>
          <div style="display: inline-block;">
            <button mat-button color="primary" (click)="changeRunRoute('submission');">
              <i class="material-icons color-white">favorite</i>
              Submitted: {{this.appService.Run.submittedDate != null ? 'Yes' : 'No'}}
            </button>
          </div>
        </div>

      </fieldset>
      <div class="content">
        <!--mat-elevation-z8-->
        <mat-spinner *ngIf="appService.isLoading | async"
                     style="position: absolute; top: 0; z-index: 5000; top: 40%; left: 48.2%;"></mat-spinner>
        <router-outlet [hidden]="appService.isLoading | async">
        </router-outlet>
        <br /><br />

      </div>
      <div id="footerCopyRight">
        © {{currentYear}} - Extracorporeal Life Support Organization, Ann Arbor, Michigan, USA. (Registry version - {{appService.registryVersion}})
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
