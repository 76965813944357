import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-testDP',
  templateUrl: './testDP.component.html',
  styleUrls: ['./testDP.component.css']
})
export class TestDPComponent implements OnInit {

  constructor() {
  }
  showMonthPicker = true;
  showMonthLabel = true;
  showMonthList = false;
  showMonthInput = false;
  monthModel = null;

  months = [
    { name: 'Month', id: '', behindFilter: 'z' },
    { name: 'Jan', id: 0, behindFilter: 'Jan01' },
    { name: 'Feb', id: 1, behindFilter: 'Feb02' },
    { name: 'Mar', id: 2, behindFilter: 'Mar03' },
    { name: 'Apr', id: 3, behindFilter: 'Apr04' },
    { name: 'May', id: 4, behindFilter: 'May05' },
    { name: 'Jun', id: 5, behindFilter: 'Jun06' },
    { name: 'Jul', id: 6, behindFilter: 'Jul07' },
    { name: 'Aug', id: 7, behindFilter: 'Aug08' },
    { name: 'Sep', id: 8, behindFilter: 'Sep09' },
    { name: 'Oct', id: 9, behindFilter: 'Oct10' },
    { name: 'Nov', id: 10, behindFilter: 'Nov11' },
    { name: 'Dec', id: 11, behindFilter: 'Dec12' }
  ];


  selectedMonth = {
    name: 'Month', id: '', behindFilter: 'z'
  };

  onBlur(pickerPart) {

  }

  ngOnInit(): void {
    console.log('testdp component');
  }

  onPickerClick(pickerPart) {
    console.log('aaa');

    switch (pickerPart) {
      case 'month':
        this.showMonthList = true;
        this.showMonthInput = true;
        this.showMonthLabel = false;
        setTimeout(function () {
           document.getElementById('month').focus();

        }, 0);

        break;
    }
  }

  closeInput(pickerPart) {
    switch (pickerPart) {
      case 'month':
        this.showMonthList = false;
        this.showMonthInput = false;
        this.showMonthLabel = true;

        break;
    }
  }

  filter(pickerPart) {
    console.log('aaaa');
    switch (pickerPart) {
      case 'month':
        

        break;
    }
  }
  
}
