import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import '../../dbr'; // import side effects. The license, engineResourcePath, so on.
import { BarcodeScanner } from 'dynamsoft-javascript-barcode';

@Component({
  selector: 'app-device-scanner',
  templateUrl: './deviceScanner.component.html',
  styleUrls: ['./deviceScanner.component.css']
})
export class DeviceScannerComponent implements OnInit {
  bShowScanner = false;
  bShowImgDecode = false;
  disabled = true;
  @Output() barCodeScanned: EventEmitter<any> = new EventEmitter();

  async ngOnInit(): Promise<void> {
    // Load the library on page load to speed things up.
    try {
      await BarcodeScanner.loadWasm();
    } catch (ex: any) {
      let errMsg;
      if (ex.message?.includes("network connection error")) {
        errMsg = "Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.";
      } else {
        errMsg = ex.message || ex;
      }
      console.error(errMsg);
      alert(errMsg);
    }
  }
  showScanner(): void {
    this.bShowScanner = true;
    this.bShowImgDecode = false;
  }
  showImgDecode(): void {
    this.bShowScanner = false;
    this.bShowImgDecode = true;
  }
  listenForChildChange(event: any) {
    this.barCodeScanned.emit(event);
  }
}
